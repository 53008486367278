// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

:root {
	--app-height: 100%;
}

// Header Skins
.kt-header-base-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

// Primefaces themes
@import "../node_modules/primereact/resources/primereact.min.css";
@import "../node_modules/primereact/resources/themes/nova-light/theme.css";
@import "../node_modules/primeicons/primeicons.css";

@-webkit-keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUpContent {
	-webkit-animation-name: fadeInUpContent;
	animation-name: fadeInUpContent;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--active
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--active
	> .kt-menu__link
	.kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
	> .kt-menu__link
	.kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--open
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
	> .kt-menu__heading
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
	> .kt-menu__link
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span {
	background-color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item.kt-menu__item--active
	> .kt-menu__heading
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item.kt-menu__item--active
	> .kt-menu__link
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span {
	background-color: #607d8b;
}

.btn-primary {
	background-color: #147dbf !important;
	border-color: #147dbf !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	background-color: #36a5eb !important;
	border-color: #36a5eb !important;
	color: #fff !important;
}

.btn-primary:disabled {
	background-color: #67666e !important;
	border-color: #67666e !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #67666e !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #67666e !important;
}

.MuiInputLabel-shrink,
.MuiFormHelperText-root,
.MuiInputLabel-outlined {
	font-family: "Roboto" !important;
}

.MuiAutocomplete-tag {
	max-width: inherit !important;
}

.margin-icon {
	margin-right: 8px;
}

.btn-table-action > i {
	width: 40px !important;
	font-size: 13px !important;
	padding: 0 !important;
}

.btn-table-action {
	margin: 0px 3px 0px 3px;
	height: 40px;
	width: 40px;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.btn-table-action-small > i {
	width: 35px !important;
	font-size: 12px !important;
}

.btn-table-action-small {
	height: 30px;
	width: 30px;
	padding: 0 !important;
}

.p-datatable-emptymessage {
	height: 100px !important;
	text-align: center !important;
	font-size: 21px !important;
	font-weight: 600 !important;
}

.p-paginator-bottom {
	padding: 10px !important;
	background: #fff !important;
	border: 1px solid #f4f4f4 !important;
	border-bottom-right-radius: 8px !important;
	border-bottom-left-radius: 8px !important;
	text-align: right !important;
}

.p-paginator-current {
	color: #434349 !important;
}

.p-paginator-page.p-highlight {
	background-color: #147dbf !important;
}

.p-paginator-element.p-link {
	margin: 0px 2px 0px 2px !important;
	border-radius: 5px !important;
}

.p-button {
	margin: 0;
	background-color: #147dbf !important;
	border-radius: 0.25rem !important;
	border: none !important;
}

.p-datatable .p-datatable-thead > tr > th {
	text-align: left !important;
	background-color: #fff !important;
}

.p-datatable .p-datatable-thead > tr {
	height: 50px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable .p-datatable-thead > tr > th {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable-header {
	height: min-content;
	background-color: #fff !important;
	border: none !important;
	padding-right: 0 !important;
}

.p-datatable-loading-overlay.p-component-overlay {
	background-color: #fff !important;
}

.p-datatable-loading-content {
	top: 15% !important;
}

.p-inputtext {
	border: 1px solid #e2e1e1 !important;
}

label.p-dropdown-label.p-inputtext {
	border: none !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #67666e !important;
}

.MuiInput-underline:focus:not(.Mui-focused):before,
.MuiInput-underline:active:not(.Mui-focused):before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: 1px solid #878691 !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #878691 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #67666e !important;
}

.btn > i.fa-spin {
	padding-right: 0px !important;
	margin-right: 16px !important;
}

.kt-portlet {
	margin: 20px !important;
}

.kt-portlet__head-title {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.anexoBtnRemove {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.anexoBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;
}

.anexoContainer {
	margin-top: 15px;
	margin-bottom: 15px;
}

.kt-notification__item-icon > i {
	color: #147dbf !important;
}

body a:hover {
	color: #147dbf !important;
}

.kt-login__title {
	color: #424242 !important;
	font-weight: 600 !important;
}

.kt-login__subtitle {
	font-size: 1.3rem !important;
	color: #616161 !important;
}

.kt-login__copyright {
	color: #424242 !important;
}

.kt-login__menu > a {
	text-decoration: none !important;
	color: #424242 !important;
	text-shadow: 1px 1px 5px #fff !important;
}

.kt-login__menu > a:hover {
	text-decoration: none !important;
	color: #212121 !important;
}

.kt-scrolltop {
	background: #147dbf !important;
}

.btn-app-color {
	border-color: #147dbf !important;
	background-color: white !important;
	color: #147dbf !important;
}

.btn-app-color:hover {
	border-color: #147dbf !important;
	background-color: #147dbf !important;
	color: white !important;
}

.MuiInputBase-input.Mui-disabled {
	color: rgba(0, 0, 0, 0.65) !important;
}

.atalho-dashboard {
	background-color: #147dbf !important;
	color: white !important;
	height: 200px !important;
	font-weight: 600 !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.atalho-dashboard > i {
	font-size: 4.5rem !important;
	margin: 0px 0px 25px 0px !important;
}

.atalho-dashboard > h3 {
	font-size: 1.8rem !important;
	font-weight: 600 !important;
}

.no-content-dashboard {
	margin: 50px 60px 50px 60px;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.no-content-dashboard > i {
	font-size: 5rem !important;
	margin: 0px 0px 40px 0px !important;
}

.no-content-dashboard > h3 {
	font-size: 2.3rem !important;
	font-weight: 600 !important;
}

.no-content-dashboard > p {
	margin-top: 10px !important;
	font-size: 1.2rem !important;
	font-weight: 300 !important;
}

.item-atendimentos-hoje:not(:last-child) {
	margin-bottom: 2.3rem !important;
}

.item-atendimentos-hoje > .kt-timeline-v2__item-text {
	font-size: 1.2rem !important;
	padding: 0.2rem 0 0 5rem !important;
}

.nav-item.nav-link {
	padding: 15px 10px 15px 10px !important;
	color: #444 !important;
	border-radius: 0 !important;
}

.nav-item.nav-link:hover:not(.active) {
	color: #999 !important;
	border: 1px solid transparent !important;
}

.nav-item.nav-link.active {
	border-radius: 0px !important;
	border-top: 3px solid #147dbf !important;
	font-weight: 500 !important;
}

.nav-item.nav-link:first-child {
	border-left: 1px solid transparent !important;
}

.nav-tabs {
	border-bottom: 1px solid #ddd !important;
}

.tab-pane {
	padding: 0px 25px 25px 25px !important;
}

a.btn:hover {
	color: white !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
	color: #147dbf !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
	background-color: rgba(20, 125, 191, 0.5) !important;
}

.MuiSwitch-thumb {
	box-shadow: none !important;
}

.col-btn-with-input {
	display: flex;
	align-items: flex-end;
	padding-bottom: 8px;
}

.btn-with-input {
	height: 53.63px;
}

.label-with-texfield {
	margin-bottom: 0px !important;
}

.tableHeaderItem {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}

.form-control:focus {
	border-color: #607d8b;
}

.btn.btn-label-brand {
	background-color: white !important;
	color: #147dbf !important;
}

.btn.btn-label-brand:hover,
.btn.btn-label-brand:focus {
	background-color: #147dbf !important;
	color: white !important;
}

.empty-icon {
	margin-top: 50px;
	font-size: 4.2rem;
}

.empty-text {
	margin: 50px 25px 50px 25px;
	font-size: 1.5rem;
}

.scroll::-webkit-scrollbar {
	width: 6px;
	height: 10px;
}

.scroll::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}

.scroll::-webkit-scrollbar-thumb {
	background: #808080;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-thumb:hover {
	background: #808080;
}

.scroll::-webkit-scrollbar-thumb:active {
	background: #808080;
}

.scroll::-webkit-scrollbar-track {
	background: #ccc;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-track:hover {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-track:active {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-corner {
	background: transparent;
}

.swal2-popup {
	border-radius: 15px !important;
}

.btn-filtros {
	margin-right: 5px;
}

.imagemUploaderBotaoRemover {
	max-width: 250px;
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.imagemUploaderBackground {
	height: 250px;
	max-width: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;
}

.imagemUploaderMargem {
	margin-top: 15px;
	margin-bottom: 15px;
}

.buttonCenter {
	vertical-align: middle;
}

.forgot-password,
.forgot-password:hover {
	color: rgba(0, 0, 0, 0.54);
}

.container-map {
	position: relative;
	max-width: 100%;
	height: calc(100vh - 65px);
}

.cluster-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-position: center;
	background-repeat: no-repeat;
}

.cluster-container-one {
	margin-top: -40px;
	margin-left: -20px;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.text-cluster-one {
	margin-left: -102px;
	margin-top: 5px;
	width: 200px;
	text-align: center;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	color: #000000;
	word-break: break-all;
}

.cluster-red {
	margin: -29px 0px 0px -29px;
	background-image: url("/images/tracking/cluster-red.png");
	width: 58px;
	height: 58px;
}

.cluster-yellow {
	margin: -21px 0px 0px -21px;
	background-image: url("/images/tracking/cluster-yellow.png");
	width: 42px;
	height: 42px;
}

.cluster-blue {
	margin: -21px 0px 0px -21px;
	background-image: url("/images/tracking/cluster-blue.png");
	width: 42px;
	height: 42px;
}

.cluster-terminal {
	background-image: url("/images/tracking/pin-terminal.svg");
}

.cluster-estacao {
	background-image: url("/images/tracking/pin-estacao.svg");
}

.cluster-composicao {
	background-image: url("/images/tracking/pin-train.svg");
}

.cluster-composicao-down {
	background-image: url("/images/tracking/pin-train-down.svg");
}

.cluster-composicao-up {
	background-image: url("/images/tracking/pin-train-up.svg");
}

.cluster-composicao-orientado-down {
	background-image: url("/images/tracking/pin-train-orientado-down.svg");
}

.cluster-composicao-orientado-up {
	background-image: url("/images/tracking/pin-train-orientado-up.svg");
}

.natal {
	background-image: url("https://img2.gratispng.com/20180626/esi/kisspng-santa-claus-train-christmas-clip-art-rail-5b32c45bb91190.8444516115300537237581.jpg") !important;
	pointer-events: none;
}

.cluster-vagao {
	width: 150px;
	height: 30px;
	margin-left: -45px;
}

.text-cluster {
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	color: #000000;
	margin-bottom: 0;
}

.button-flutuante,
.button-flutuante-bottom {
	z-index: 99999;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px;
	width: 60px;
	margin-left: 40px;
	margin-top: 30px;
	background-color: #1a1a27;
	border-radius: 50%;
	border: none;
	box-shadow: 9px 1px 9px -3px rgba(0, 0, 0, 0.3);
}

.button-flutuante-bottom {
	position: sticky !important;
	left: calc(100% - 100px);
	right: 40px;
	bottom: 50px;
}

.button-flutuante-bottom:not(:first-of-type) {
	left: calc(100% - 180px);
}

.fixed-bottom {
	position: sticky;
	display: flex;
	bottom: 50px;
}

.button-flutuante:not(:first-child) {
	margin-top: 120px;
}

.button-flutuante i,
.button-flutuante-bottom i {
	color: #fff;
	font-size: 17px;
}

.btn-link:hover,
.btn-link:focus {
	background: none !important;
	text-decoration: none !important;
	color: inherit !important;
}

.group-label {
	display: flex;
	align-items: center;
	padding: 10px 15px;
	font-size: 11px;
	text-transform: uppercase;
}

.modal-body-filter {
	padding-top: 10px;
	max-height: calc(100vh - 250px) !important;
	overflow-y: scroll;
}

.modal-info-tracking {
	position: absolute;
	overflow: hidden;
	right: 0;
	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 40px;
	padding: 8px;
	background: #ffffff;
	width: 325px;
	height: min-content;
	border-radius: 10px;
	box-shadow: -1px 3px 9px 3px rgba(0, 0, 0, 0.3);
}

.modal-info-header {
	display: flex;
	justify-content: center;
	padding: 10px 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.title-tracking {
	text-transform: capitalize;
	color: #003764;
	font-size: 20px;
	font-weight: 600;
}

.modal-info-content {
	padding: 10px 0px;
	max-height: 350px;
	overflow-y: auto;
	overflow-x: hidden;
}

.modal-info-footer {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 10px 0px 10px 0px;
}

.modal-info-footer p {
	text-transform: capitalize;
	text-align: center;
	margin-bottom: 0px;
}

.breadcrumb-tracking {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.breadcrumb-button,
.breadcrumb-button-active {
	margin: 1px 2px;
	font-size: 10px;
	padding: 4px;
	text-transform: capitalize;
	color: #969696;
	background-color: #ffff;
	border: 1px solid #969696;
	border-radius: 4px;
}

.breadcrumb-button-active {
	color: #003764;
	border: 1px #003764 solid;
}

.breadcrumb-button:hover {
	background-color: #f2f2f2;
	border: 1px #003764 solid;
}

.button-info-tracking,
.button-filter-tracking {
	padding: 8px 0px;
	width: 100%;
	background: none;
	border: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.button-filter-tracking {
	color: #003764;
	padding: 16px 0px;
	font-weight: 600;
	font-size: 16px;
}

.button-filter-tracking i {
	font-size: 20px;
}

.button-filter-tracking p {
	margin-bottom: 0px;
}
.hours .MuiFormControl-marginNormal {
	margin-top: 0 !important;
	margin-bottom: 12px !important;
}

.button-info-tracking h5 {
	font-size: 12px;
	font-weight: 600;
}

.button-info-tracking h4 {
	font-size: 18px;
	font-weight: 600;
	color: #003764;
}

.button-info-tracking h4 i {
	font-size: 12px;
	margin-left: 8px;
	margin-right: 8px;
}

.button-info-tracking p {
	margin-bottom: 4px;
}

.button-info-tracking p b {
	color: #003764;
}

.listing-tracking {
	padding: 5px 15px;
	text-align: start;
	justify-content: start;
}

.listing-tracking h4 {
	text-transform: uppercase;
	font-size: 15px;
	margin-bottom: 2px;
}

.listing-tracking p {
	color: rgba(0, 0, 0, 0.54);
	font-size: small;
	margin-bottom: 2px;
}

.vagao-info-tracking .row {
	align-items: center;
	padding: 5px 0px;
}

.vagao-info-tracking .row:hover {
	background: #f8f8f8;
}

.vagao-info-tracking span {
	font-size: 12px;
}

.vagao-info-tracking p {
	font-size: 13px;
	color: #003764;
	font-weight: 600;
	margin-bottom: 0px;
}

.modal {
	padding-left: 0px !important;
}

.container-grafico {
	border: 1px solid #dbeaf5;
	border-radius: 5px;
	width: calc(100% - 10px);
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.totalizadores-fila {
	margin-top: 20px;
	height: 50px;
	border-top: 1px solid #dbeaf5;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	color: #48465b;
	text-align: center;
}

.totalizadores-controle-fila {
	border-top: none;
	height: auto;
	margin-top: 0px;
}

.totalizadores-fila p {
	display: flex;
	align-items: center;
	margin-left: 18px;
	margin-bottom: 4px;
}

.totalizadores-fila b {
	margin: 0px 3px;
}

.totalizadores-fila b {
	color: #003764;
}

.container-fila {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.container-fila .composicoes {
	display: flex;
	flex-wrap: wrap;
}

.item-fila {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: fit-content;
	width: 130px;
	margin: 4px;
	background-color: transparent;
	padding: 4px;
	border: none;
}

.item-fila img {
	max-width: 100%;
}

.item-fila p {
	width: max-content;
	font-weight: 700;
	text-align: center;
	color: #000000;
	font-size: 10px;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 0px;
	overflow: visible;
}

.button-adornment {
	border: none;
	background: none;
	color: #48465b;
}

.tooltip-composicao {
	margin-top: -40px;
	width: max-content !important;
}

.tooltip-info {
	padding: 10px 5px;
	text-align: start;
}

.tooltip-info h4 {
	margin: 8px 0px;
	font-size: 16px;
	font-weight: 600;
	color: #003764;
}

.tooltip-info i {
	margin-right: 8px;
}

.tooltip-info h5 {
	padding: 6px;
	margin-bottom: 10px;
	width: 100%;
	color: #000000;
	background-color: #dbeaf5;
	font-size: 14px;
	text-transform: uppercase;
}

.tooltip-info p {
	margin-bottom: 8px;
	color: #48465b;
}

.container-ask {
	position: relative;
}

.dropmenu-ask {
	position: absolute;
	left: 0;
	transform: translate(-100%);
	background-color: #ffffff;
	padding: 16px;
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
}

.dropmenu-ask a {
	width: 100%;
	height: 48px;
	color: #48465b;
}

.tooltip-composicao {
	position: absolute;
	top: 0;
	left: 60%;
	transform: translate(-50%, -105%);
	padding: 8px;
	background: #ffffff;
	width: 225px;
	height: min-content;
	border-radius: 10px;
	box-shadow: -1px 3px 9px 3px rgba(0, 0, 0, 0.3);
}

.tooltip-composicao button {
	position: absolute;
	right: 0px;
	margin-top: 5px;
	margin-right: 10px;
	background: none;
	border: none;
	color: #003764;
	font-size: 14px;
}

.form-group {
	margin-bottom: 1rem !important;
}

.estoque-total {
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.estoque-total p {
	margin-bottom: 4px;
}

.permissao {
	margin-bottom: 20px;
}

.permissao h4,
.permissao p {
	color: #000000;
}

.permissao label {
	margin-bottom: 0px !important;
}

.permissao p {
	margin-bottom: 20px;
}

.data-sincronizacao {
	position: absolute;
	z-index: 99999;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: space-around;
	width: 100%;
	max-width: 600px;
	background: #fff;
	color: #000;
	padding: 6px 12px;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	box-shadow: -1px 3px 9px 3px rgba(0, 0, 0, 0.3);
}

.data-sincronizacao p {
	margin-bottom: 0px;
}

.notification {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.notification-not-read {
	background-color: #f7f7f7;
	color: #003764;
}

.notification p {
	padding: 20px;
	margin-bottom: 0px;
}

.notification-icon {
	margin: 0 10px 0 10px;
	font-size: 1.2rem;
}

.notification-not-read .notification-icon {
	color: #eb4432;
}

.notification-date {
	font-size: 1.1rem;
	margin: 0 10px 0 10px;
}

.notification-title {
	font-size: 1.2rem;
	font-weight: 600;
}

.notification-subtitle {
	font-size: 1.1rem;
	margin-left: 10px;
}

.header-notification,
.header-notification-active {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
	font-size: 18px;
	color: #48465b;
}

.header-notification-active {
	color: #147dbf;
}

.count-notification {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 25px;
	width: auto;
	height: 25px;
	margin-left: -2px;
	margin-bottom: 20px;
	font-size: 10px;
	font-weight: 500;
	padding: 4px;
	border-radius: 15px;
	color: #ffffff;
	background-color: #147dbf;
}

.button-pwa {
	display: flex;
	background: none;
	border: none;
	font-size: 18px;
	font-weight: 600;
}

.button-pwa span {
	font-size: 12px;
	margin-right: 8px;
}

body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
body .p-datatable .p-sortable-column.p-highlight {
	color: #848484;
}

.agendamento-totalizadores {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 16px;
	border: 1px solid #ebedf2;
	border-radius: 8px;
	text-align: center;
}

.agendamento-totalizadores h4 {
	font-size: 16px;
	color: #48465b;
}

.agendamento-totalizadores p {
	font-size: 22px;
	margin-bottom: 0px;
}

.agendamento-totalizadores-tabela {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	padding: 10px 20px 0px 20px;
	border: 1px solid #ebedf2;
}

.agendamento-atualizacao {
	color: #48465b;
	font-weight: 600;
	font-size: 16px;
}

@media screen and (max-width: 1394px) {
	.estoque-total {
		margin-top: 20px;
	}
}

@media screen and (max-width: 996px) {

	.modal-info-content {
		padding: 10px 0px;
		max-height: 250px;
		overflow-y: auto;
		overflow-x: hidden;
	}
	
	.container-map {
		height: calc(var(--app-height) - 50px);
	}

	.data-sincronizacao {
		top: 0;
		bottom: auto;
		border-radius: 0px;
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	.data-sincronizacao p {
		display: flex;
		flex-direction: column;
	}

	.data-sincronizacao p:first-of-type {
		padding-right: 20px;
	}

	.dropmenu-ask {
		width: 100%;
		bottom: 0;
		transform: translate(0%, 100%);
	}

	.kt-portlet {
		margin: 5px !important;
	}

	.totalizadores-fila p {
		display: block;
	}

	.totalizadores-fila b {
		margin: 0px;
	}

	.totalizadores-fila p::before {
		display: none;
	}

	.container-fila img {
		width: 40px;
	}

	.item-fila img {
		width: 95%;
	}

	.button-flutuante {
		margin-left: 10px;
		margin-top: 65px;
	}

	.button-flutuante:not(:first-child) {
		margin-top: 130px;
	}

	.modal-info-tracking {
		border-radius: none !important;
		width: 100%;
		max-width: 450px;
		margin: 0;
		margin-left: auto;
		margin-right: auto;
		height: auto;
		max-height: calc(100% - 200px);
		left: 0;
		right: 0;
		bottom: 0;
		top: auto;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.container-grafico {
		margin-top: 10px;
	}
}
.tst .fa-spin {
	display: none !important;
}
.cli-export {
	position: absolute;
	top: 200px;
	z-index: 9999;
}
@media screen and (max-width: 1250px) {
	.cli-export {
	position: relative;
    top: 0px;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
    z-index: 9999;
	}
}
.collors {
	display: flex;
	align-items: center;
	padding-bottom: 25px;
}
.collors h6 {
	margin-left: 15px;
  margin-bottom: 0;
}

.logo-empresa-header {
	display: block;margin: auto;
	max-width: 100px;
	max-height: 50px;
	margin: 10px 0px 10px 20px;
}

.logo-empresa-header-mob {
	margin: auto;
	display: none;
}

.hdr {
	margin-top: 0px;
}

.flex-bet {
	position: relative;
}

.header-notification {
	display: flex;
}

.close-colors {
	display: flex;
	justify-content: flex-end;
	transition: all .3s;
	
	h2 {
		color: #48465b;
		text-align: center;
		width: 30px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    z-index: -1;
    background: white;
		box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.15);
		margin-bottom: 0;
	}
	h2:hover {
		cursor: pointer;
		opacity: 0.8;
	}
}
.close-colors:hover {
	cursor: pointer;
	opacity: 0.8;
}

.blocks {
	pointer-events: all !important;
}

@media screen and (max-width: 1000px) {
	.hdr {
		margin-top: 50px;
	}

	.logo-empresa-header {
		display: none;
		max-width: 115px;
    max-height: 65px;
	}

	.logo-empresa-header-mob {
		display: block;
		max-width: 130px;
    max-height: 60px;
	}

	.header-notification-active, .header-notification {
		display: none;
	}

}
@media screen and (max-width: 600px) {
	.blocks {
		pointer-events: none !important;
	}
}
.nada-filtro {
	display: none !important;
}
